import React from "react"
import Img from "gatsby-image"
import { Col } from "reactstrap"

export default props => {
    return (
        <Col md={props.md}>
            <Img fluid={props.img} />
            <div className="text-center" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                {props.children}
            </div>
        </Col>
    )
}