import React from "react"
// import Layout from "../../../Components/layout"
// import { Row, Col } from "reactstrap"
// import Img from "gatsby-image"
import { graphql } from "gatsby"

import Image from "../../../Components/pageComponents/carouselCard"
import ContentLayout from "../../../Components/pageComponents/contentContainer"
import Carousel from "../../../Components/pageComponents/carousel"


export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/computing/it-peripherals/IT Peripherals & Consumables.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        desktop: file(relativePath: { eq: "products/computing/it-peripherals/Desktops Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        workstation: file(relativePath: { eq: "products/computing/it-peripherals/Workstations Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        laptop: file(relativePath: { eq: "products/computing/it-peripherals/Laptops Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tablet: file(relativePath: { eq: "products/computing/it-peripherals/Tablets and Handhelds.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        scanner: file(relativePath: { eq: "products/computing/it-peripherals/Scanners Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        projector: file(relativePath: { eq: "products/computing/it-peripherals/Projectors Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        projection: file(relativePath: { eq: "products/computing/it-peripherals/Projection Screens Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        fax: file(relativePath: { eq: "products/computing/it-peripherals/Fax Machines.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        avr: file(relativePath: { eq: "products/computing/it-peripherals/AVR Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        windows: file(relativePath: { eq: "products/computing/it-peripherals/Windows Operating System Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        large: file(relativePath: { eq: "products/computing/it-peripherals/Large Display Formats Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        video: file(relativePath: { eq: "products/computing/it-peripherals/Video Conference Audio and Video.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wired: file(relativePath: { eq: "products/computing/it-peripherals/Wired and Wireless Digital Signage.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        led: file(relativePath: { eq: "products/computing/it-peripherals/LCD and Plasma Technology Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        whiteboard: file(relativePath: { eq: "products/computing/it-peripherals/Interactive Whiteboards Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        input: file(relativePath: { eq: "products/computing/it-peripherals/Input Devices Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        output: file(relativePath: { eq: "products/computing/it-peripherals/Output Devices Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        storage: file(relativePath: { eq: "products/computing/it-peripherals/Storage Devices Icon.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        pos: file(relativePath: { eq: "products/computing/it-peripherals/POS Peripherals.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <ContentLayout title="IT Peripherals & Consumables" image={data.banner.childImageSharp.fluid}>
            <h3 class="ff-secondary text-center p-5">
                Fastlink offers both Consumer and Enterprise IT Peripherals and Consumables:
            </h3>
            <Carousel>
                <Image img={data.desktop.childImageSharp.fluid}>
                    Desktops (Clone or Branded)
                </Image>
                <Image img={data.workstation.childImageSharp.fluid}>
                    Workstations
                    </Image>
                <Image img={data.laptop.childImageSharp.fluid}>
                    Laptops
                    </Image>
                <Image img={data.projector.childImageSharp.fluid}>
                    Projectors
                </Image>
                <Image img={data.tablet.childImageSharp.fluid}>
                    Tablets and Handhelds
                </Image>
                <Image img={data.scanner.childImageSharp.fluid}>
                    Scanners
                    </Image>
                <Image img={data.projector.childImageSharp.fluid}>
                    Projectors
                    </Image>
                <Image img={data.projection.childImageSharp.fluid}>
                    Projection Screens
                    </Image>
                <Image img={data.fax.childImageSharp.fluid}>
                    Fax Machines
                    </Image>
                <Image img={data.avr.childImageSharp.fluid}>
                    AVR
                    </Image>
                <Image img={data.windows.childImageSharp.fluid}>
                    Windows Operating System
                    </Image>
                <Image img={data.large.childImageSharp.fluid}>
                    Large Format Displays
                    </Image>
                <Image img={data.video.childImageSharp.fluid}>
                    Video Conferencing Audio Video
                    </Image>
                <Image img={data.wired.childImageSharp.fluid}>
                    Wired and Wireless Digital Signage
                    </Image>
                <Image img={data.led.childImageSharp.fluid}>
                    LED
                    </Image>
                <Image img={data.whiteboard.childImageSharp.fluid}>
                    Interactive Whiteboards
                    </Image>
                <Image img={data.output.childImageSharp.fluid}>
                    <h5 class="ff-secondary">Output Devices</h5>
                    <ul class="text-left ml-5">
                        <li>Monitor</li>
                        <li>Multi-Function Printers</li>
                        <li>Plotters and Large Format Printers</li>
                    </ul>
                </Image>
                <Image img={data.pos.childImageSharp.fluid}>
                    <h5 class="ff-secondary">POS Peripherals</h5>
                    <ul class="text-left ml-5">
                        <li>Scanner</li>
                        <li>Receipt Printer</li>
                        <li>Customer Display</li>
                        <li>Cash Drawer</li>
                    </ul>
                </Image>
                <Image img={data.storage.childImageSharp.fluid}>
                    <h5 class="ff-secondary">Storage Devices</h5>
                    <ul class="text-left ml-5">
                        <li>Hard Drive and Flash Drive</li>
                    </ul>
                </Image>
            </Carousel>

        </ContentLayout>
    )
}